import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Axios from "axios"

import { Container, ButtonGroup, ToggleButton, Button } from "react-bootstrap"

const Main = () => {
  const [stream, setStream] = useState([])
  useEffect(() => {
    const getData = async () => {
      await Axios.get(`https://api.puntohost.com/2/streaming.php`).then(
        response => {
          const result = response.data
          setStream(result)
        }
      )
    }
    getData()
  }, [])

  const [currencyValue, setCurrencyValue] = useState("1")

  const currencies = [
    { name: "Dólares", value: "1" },
    { name: "Bolívares", value: "2" },
  ]

  return (
    <Layout>
      <Seo
        title="Audio Streaming"
        description="Radio en internet, transmite las 24 horas sin cortes."
      />
      <div className="h-streaming">
        <div className="text-center pt-6 pb-5">
          <Container>
            <h1>Audio Streaming: La radio evolucionó.</h1>
            <p>Tu Radio en internet, sin cortes</p>
          </Container>

          <ButtonGroup
            data-toggle="buttons"
            className="btn-group-toggle btn-group"
            role="group"
            aria-label="Cambio de moneda"
          >
            {currencies.map((currency, idx) => (
              <ToggleButton
                key={idx}
                id={`currency-${idx}`}
                type="radio"
                variant="dark"
                name="currency"
                value={currency.value}
                checked={currencyValue === currency.value}
                onChange={e => setCurrencyValue(e.currentTarget.value)}
              >
                {currency.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </div>

        <Container className="text-right cc"></Container>
      </div>

      <section id="pricing" className="container">
        {currencyValue === "1" ? (
          <>
            <div className="row">
              {stream.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <p>
                        <span className="discount">En oferta: Ahorra 50%</span>
                        <br />
                        <span className="prefix">{data.usd.prefix}</span>
                        <span>{(data.usd.price.monthly / 2).toFixed(2)}</span>
                        <span className="mes">/mes</span>
                        <p className="renew text-muted">
                          {data.usd.prefix}
                          {(data.usd.price.monthly / 1).toFixed(2)}
                          /mes cuando renuevas
                        </p>
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.usd.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.usd.url}`}
                      >
                        Contratar
                      </Link>
                    </div>

                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="row">
              {stream.map((data, ...index) => (
                <div key={index} className="col-md-6 col-lg-4 single-pricing-2">
                  <div className="inner">
                    <div className="title">
                      <p>{data.name.split(" - ")[1]}</p>
                    </div>
                    <div className="price">
                      <span className="discount">En oferta: Ahorra 50%</span>
                      <br />
                      <span className="prefix">{data.ves.prefix}</span>
                      <span>{(data.ves.price.monthly / 2).toFixed(0)}</span>
                      <span className="mes">/mes</span>
                      <p className="renew text-muted">
                        {data.ves.prefix}
                        {data.ves.price.monthly / 1}/mes cuando renuevas
                      </p>
                    </div>

                    <div className="btn-buy">
                      <Link
                        id={`${data.name}-${data.ves.suffix}`}
                        className="btn btn-primary btn--semi-large"
                        to={`${data.ves.url}`}
                      >
                        Contratar
                      </Link>
                    </div>

                    <ul
                      dangerouslySetInnerHTML={{
                        __html: data.description,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </section>

      <div className=" container text-center pb-5">
        <p>
          ¿Necesitas probar antes de comprar? Solicita hoy mismo una prueba
          GRATIS para nuestro servicio de Radio Online durante 7 días.
        </p>
        <Button variant="danger">
          <a
            href="https://my.puntohost.com/order/main/packages/audio-streaming/?group_id=3&pricing_id=158&currency=USD"
            className="text-decoration-none text-white"
            id="cta-streaming-trial"
          >
            Solicitar prueba de servicio
          </a>
        </Button>
      </div>

      <div className="player">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2 className="text-white pt-5">
                Escuche nuestra radio de prueba
              </h2>
              <p>
                <i className="icon-52 text-white"></i>
                <a href="#pricing" className="text-white">
                  {" "}
                  Streaming bajo HTTPS - !Contrate ahora!
                </a>{" "}
              </p>
            </div>
            <div className="col-md-6">
              <div className="pt-5">
                <embed
                  src="https://mejorenlaradio.com/player/luna/index.html"
                  width="100%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center pt-5">
        <div className="container">
          <div className="section-title">
            <h2>Gratis para tu radio.</h2>
            <p>¡Todo lo que necesitas para hacerte escuchar!</p>
          </div>
        </div>
      </div>

      <section id="features" className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-80 icon-2x"></i>
              <p className="f-title">Panel de Control</p>
              <p>
                Gestiona tu radio desde el panel Centovacast, fácil, intuitivo y
                en español.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-43 icon-2x"></i>
              <p className="f-title">Listas de reproducción</p>
              <p>
                Configura tu programación también en el servidor, bloques de
                música o publicidad.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-11 icon-2x"></i>
              <p className="f-title">Estadisticas detalladas</p>
              <p>
                Visualice sus oyentes en tiempo real y exporte reportes
                detallados.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-55 icon-2x"></i>
              <p className="f-title">AutoDJ</p>
              <p>
                Transmite las 24 horas del día. Emite en vivo o reproduce desde
                el servidor.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-4 icon-2x"></i>
              <p className="f-title">Activación inmediata</p>
              <p>
                En menos de 3 minutos su servicio estará activo y listo para
                usar.
              </p>
            </div>
            <div className="col-lg-4 col-sm-6 pb-5">
              <i className="icon-63 icon-2x"></i>
              <p className="f-title">Soporte inmejorable</p>
              <p>
                Nuestros agentes están siempre disponibles para ofrecerte un
                soporte rápido.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Main
